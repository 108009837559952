<script lang="ts" setup>
import { twJoin } from "tailwind-merge";
import { PLAYLIST_TYPE } from "~/constants";
import type { IPlayerState } from "~/modules/pixellot-sdk";
import type { IBasicVideo, IEvent, IPlaylist } from "~/types";
import formatDate from "~/helpers/format-date";

const props = defineProps<{
  video?: IEvent | IBasicVideo | null;
  playlist?: IPlaylist | null;
  isInPiPMode?: boolean;
  playerError?: boolean;
  playerState: IPlayerState | null;
  close: () => void;
  togglePiPMode: () => void;
}>();
const { t } = useI18n();
const isMenuAvailable = computed<boolean>(() => {
  if (props.playlist?.type === PLAYLIST_TYPE.SYSTEM) {
    return false;
  }

  if (props.video?.type === "mbe-event") {
    return false;
  }

  return true;
});
const isDisabled = computed<boolean>(
  () => !props.video || !(props.video.urls.has("hd") || props.video.urls.has("pano")) || props.playerError,
);
const isViewsShown = computed<boolean>(
  () => props.video?.type !== "mbe-event" && !props.isInPiPMode && !props.playlist && !!props.video,
);
const isShareAvailable = computed<boolean>(
  () => !!props.playlist || props.video?.type !== "event" || ((props.video as IEvent)?.permissions?.canShare ?? false),
);

function getShareTime(): number {
  return props.playerState?.currentTime ? Number(props.playerState.currentTime.toFixed(0)) : 0;
}
</script>

<template>
  <div
    class="flex items-center justify-between"
    :class="twJoin([isInPiPMode ? 'cursor-move' : '', isViewsShown ? 'py-3 px-4' : 'p-4'])"
  >
    <div :class="isInPiPMode ? 'max-w-full' : 'max-w-[calc(100%-90px)]'">
      <div class="text-black dark:text-white font-bold text-base truncate">
        {{ playlist?.name || video?.title }}
        <span
          v-if="playlist"
          class="text-neutral-light-800 dark:text-neutral-dark-200"
        >
          {{ playlist.size }}
        </span>
      </div>
      <p
        v-if="isViewsShown"
        class="text-neutral-light-800 dark:text-neutral-dark-200 font-medium text-sm"
      >
        {{ t("labels.views") }}: {{ video!.views_count }}
      </p>
      <p
        v-else-if="(props.video as IEvent)?.start_date"
        class="text-neutral-light-800 dark:text-neutral-dark-200 font-medium text-sm"
      >
        {{ formatDate((props.video as IEvent)?.start_date, "MMMM DD, YYYY hh:mm a") }}
      </p>
    </div>

    <!-- ACTION BUTTONS -->
    <div
      v-if="!isInPiPMode"
      class="flex gap-4 items-center"
    >
      <UMenu
        v-if="isMenuAvailable"
        :trigger="{ icon: 'dots', variant: 'link-secondary', disabled: isDisabled }"
      >
        <UList>
          <MenuItemVideoShare
            v-if="isShareAvailable"
            :video="video"
            :playlist="playlist"
            :get-shared-time="getShareTime"
          />
          <MenuItemVideoBookmark
            :video="video"
            :playlist="playlist"
          />
          <MenuItemVideoDownload
            v-if="video"
            :video="video"
            type="any"
          />
          <MenuItemVideoDownload
            v-if="video"
            :video="video"
            type="hd"
          />
          <MenuItemVideoDownload
            v-if="video"
            :video="video"
            type="pano"
          />
          <MenuItemVideoDelete
            :video="video"
            :playlist="playlist"
          />
        </UList>
      </UMenu>

      <UButton
        size="lg"
        variant="link-secondary"
        :icon="{ name: 'picture-in-picture', class: 'h-5 w-5' }"
        :disabled="isDisabled"
        @click="togglePiPMode()"
      />

      <UButton
        size="lg"
        variant="link-secondary"
        :icon="{ name: 'close', class: 'h-5 w-5' }"
        @click="close()"
      />
    </div>
  </div>
</template>
