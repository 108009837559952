import { default as billingFXq1rc9kpeMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue?macro=true";
import { default as breakdownsdt8O92k3UvMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue?macro=true";
import { default as generalJ727iqocYYMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue?macro=true";
import { default as index5CIgPOhXGBMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue?macro=true";
import { default as orders5lP92P7q5DMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue?macro=true";
import { default as subscriptions863dERUfdqMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue?macro=true";
import { default as bio8QuB7WX0aSMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue?macro=true";
import { default as chartszXwukM6VTkMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue?macro=true";
import { default as eventsEPlpvZC8LKMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue?macro=true";
import { default as indexM7Et5XBMBhMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue?macro=true";
import { default as statsHK2j1IHx3gMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue?macro=true";
import { default as _91athleteId_93MpQx00LAF4Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue?macro=true";
import { default as _91athleteId_93MDdTPBNrJFMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue?macro=true";
import { default as _91type_93vOnhWyGQyRMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue?macro=true";
import { default as indexj8xBrAC07YMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue?macro=true";
import { default as mergeVJVzwyCBR3Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue?macro=true";
import { default as newNIeIa3m8TiMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue?macro=true";
import { default as recoveryh0lAtQ3unIMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue?macro=true";
import { default as verificationSSJ0RGyxceMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue?macro=true";
import { default as organizationjzRGSL5tccMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue?macro=true";
import { default as teamDNdOGNJoKOMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue?macro=true";
import { default as loginta69GBK0H4Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue?macro=true";
import { default as forgot_45mail_45sentFXXRzy35RPMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue?macro=true";
import { default as forgotwjsABlfmeBMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue?macro=true";
import { default as resetDAFcvkEit4Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue?macro=true";
import { default as redirect2w0y8fAVwZMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue?macro=true";
import { default as indexMGPlWhHvGpMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue?macro=true";
import { default as localoCRac2QUYtMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue?macro=true";
import { default as _91_46_46_46slug_938uaShgaUDmMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue?macro=true";
import { default as athlete_45stats7KaXkY4DUWMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue?macro=true";
import { default as _91type_93iIEVko11MSMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue?macro=true";
import { default as indexvg1CtJ2Mr3Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue?macro=true";
import { default as indexB7uKF2OGsIMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue?macro=true";
import { default as play_45by_45playZWcp1coIlpMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue?macro=true";
import { default as team_45statsNmrxDu7bOIMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue?macro=true";
import { default as _91eventId_93SBydYrmjCzMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue?macro=true";
import { default as _91eventId_93CQ5qBpYoTVMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue?macro=true";
import { default as _91eventId_93VvIbKhlmDqMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue?macro=true";
import { default as index4MOAzubHXDMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue?macro=true";
import { default as _91type_93JrVFNsb9qEMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue?macro=true";
import { default as highlightsheSXM8Q4RfMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue?macro=true";
import { default as indexe6UbTgbNjoMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue?macro=true";
import { default as athletesuapCKyEcLxMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue?macro=true";
import { default as eventsP8AnlVx2y6Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue?macro=true";
import { default as indexL4NZOlR4FWMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue?macro=true";
import { default as leaderboardskvf3PcnfK6Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue?macro=true";
import { default as standingswcm5RXSUBfMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue?macro=true";
import { default as teamsXyLY5DOeQ3Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue?macro=true";
import { default as _91leagueId_93dsMyt5ck3uMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue?macro=true";
import { default as _91leagueId_93CMbkHF4976Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue?macro=true";
import { default as _91type_93zpiaaj6yyIMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue?macro=true";
import { default as indexPjpX9W4aiCMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue?macro=true";
import { default as newU59wsGD6wBMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue?macro=true";
import { default as adminsh2ERF2xo2mMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue?macro=true";
import { default as indexqXFCnvhRFpMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue?macro=true";
import { default as teamsxleiuOTJMVMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue?macro=true";
import { default as _91organizationId_93swIkRPYbj9Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue?macro=true";
import { default as _91organizationId_93wgdqRhjgHnMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue?macro=true";
import { default as indexgSIp5ICIKbMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue?macro=true";
import { default as newP8qZuK9OEAMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue?macro=true";
import { default as practicesEO9tffz09LMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue?macro=true";
import { default as athletesMyQaoxpW3MMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue?macro=true";
import { default as breakdowns_45and_45servicesk0QWfKO5d5Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue?macro=true";
import { default as coacheskunf4PyjkHMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue?macro=true";
import { default as events34fKnJWzjDMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue?macro=true";
import { default as indexHIzgQAzyx4Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue?macro=true";
import { default as membersxV92Gm8jWYMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue?macro=true";
import { default as opponentseek8po5eX9Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue?macro=true";
import { default as rostersl7DpkzxW3ZMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue?macro=true";
import { default as team_45statsVqtpS7gCQGMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue?macro=true";
import { default as _91teamId_93i1OEOfAKDgMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue?macro=true";
import { default as editUy4StJoNn6Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue?macro=true";
import { default as newdwVGajkUGeMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue?macro=true";
import { default as _91teamId_93qU7FIp2L6nMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue?macro=true";
import { default as _91type_93e569rP5ofEMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue?macro=true";
import { default as indexJBJFM5lJUqMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue?macro=true";
import { default as joinckEjZ8DBe9Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue?macro=true";
import { default as newJRHKHTUGq7Meta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue?macro=true";
import { default as index9DYMJpYyoWMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue?macro=true";
import { default as pixellotRxN9JKTBJBMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue?macro=true";
import { default as favoritestl83H27JbuMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue?macro=true";
import { default as indexxPfRnHz6fJMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue?macro=true";
import { default as contentK6rC5vzLgnMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue?macro=true";
import { default as profileghxNXdNo6qMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue?macro=true";
import { default as _91type_93VmezcXtqPWMeta } from "/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue?macro=true";
export default [
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___en___default",
    path: billingFXq1rc9kpeMeta?.path ?? "/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___en",
    path: billingFXq1rc9kpeMeta?.path ?? "/en/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___es",
    path: billingFXq1rc9kpeMeta?.path ?? "/es/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___de",
    path: billingFXq1rc9kpeMeta?.path ?? "/de/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___fr",
    path: billingFXq1rc9kpeMeta?.path ?? "/fr/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___it",
    path: billingFXq1rc9kpeMeta?.path ?? "/it/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___he",
    path: billingFXq1rc9kpeMeta?.path ?? "/he/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___hu",
    path: billingFXq1rc9kpeMeta?.path ?? "/hu/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___nl",
    path: billingFXq1rc9kpeMeta?.path ?? "/nl/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billingFXq1rc9kpeMeta?.name ?? "account-billing___uk-UA",
    path: billingFXq1rc9kpeMeta?.path ?? "/uk-UA/account/billing",
    meta: billingFXq1rc9kpeMeta || {},
    alias: billingFXq1rc9kpeMeta?.alias || [],
    redirect: billingFXq1rc9kpeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___en___default",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___en",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/en/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___es",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/es/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___de",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/de/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___fr",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/fr/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___it",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/it/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___he",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/he/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___hu",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/hu/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___nl",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/nl/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: breakdownsdt8O92k3UvMeta?.name ?? "account-breakdowns___uk-UA",
    path: breakdownsdt8O92k3UvMeta?.path ?? "/uk-UA/account/breakdowns",
    meta: breakdownsdt8O92k3UvMeta || {},
    alias: breakdownsdt8O92k3UvMeta?.alias || [],
    redirect: breakdownsdt8O92k3UvMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/breakdowns.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___en___default",
    path: generalJ727iqocYYMeta?.path ?? "/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___en",
    path: generalJ727iqocYYMeta?.path ?? "/en/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___es",
    path: generalJ727iqocYYMeta?.path ?? "/es/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___de",
    path: generalJ727iqocYYMeta?.path ?? "/de/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___fr",
    path: generalJ727iqocYYMeta?.path ?? "/fr/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___it",
    path: generalJ727iqocYYMeta?.path ?? "/it/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___he",
    path: generalJ727iqocYYMeta?.path ?? "/he/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___hu",
    path: generalJ727iqocYYMeta?.path ?? "/hu/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___nl",
    path: generalJ727iqocYYMeta?.path ?? "/nl/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: generalJ727iqocYYMeta?.name ?? "account-general___uk-UA",
    path: generalJ727iqocYYMeta?.path ?? "/uk-UA/account/general",
    meta: generalJ727iqocYYMeta || {},
    alias: generalJ727iqocYYMeta?.alias || [],
    redirect: generalJ727iqocYYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___en___default",
    path: index5CIgPOhXGBMeta?.path ?? "/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___en",
    path: index5CIgPOhXGBMeta?.path ?? "/en/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___es",
    path: index5CIgPOhXGBMeta?.path ?? "/es/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___de",
    path: index5CIgPOhXGBMeta?.path ?? "/de/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___fr",
    path: index5CIgPOhXGBMeta?.path ?? "/fr/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___it",
    path: index5CIgPOhXGBMeta?.path ?? "/it/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___he",
    path: index5CIgPOhXGBMeta?.path ?? "/he/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___hu",
    path: index5CIgPOhXGBMeta?.path ?? "/hu/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___nl",
    path: index5CIgPOhXGBMeta?.path ?? "/nl/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index5CIgPOhXGBMeta?.name ?? "account___uk-UA",
    path: index5CIgPOhXGBMeta?.path ?? "/uk-UA/account",
    meta: index5CIgPOhXGBMeta || {},
    alias: index5CIgPOhXGBMeta?.alias || [],
    redirect: index5CIgPOhXGBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___en___default",
    path: orders5lP92P7q5DMeta?.path ?? "/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___en",
    path: orders5lP92P7q5DMeta?.path ?? "/en/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___es",
    path: orders5lP92P7q5DMeta?.path ?? "/es/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___de",
    path: orders5lP92P7q5DMeta?.path ?? "/de/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___fr",
    path: orders5lP92P7q5DMeta?.path ?? "/fr/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___it",
    path: orders5lP92P7q5DMeta?.path ?? "/it/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___he",
    path: orders5lP92P7q5DMeta?.path ?? "/he/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___hu",
    path: orders5lP92P7q5DMeta?.path ?? "/hu/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___nl",
    path: orders5lP92P7q5DMeta?.path ?? "/nl/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orders5lP92P7q5DMeta?.name ?? "account-orders___uk-UA",
    path: orders5lP92P7q5DMeta?.path ?? "/uk-UA/account/orders",
    meta: orders5lP92P7q5DMeta || {},
    alias: orders5lP92P7q5DMeta?.alias || [],
    redirect: orders5lP92P7q5DMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___en___default",
    path: subscriptions863dERUfdqMeta?.path ?? "/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___en",
    path: subscriptions863dERUfdqMeta?.path ?? "/en/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___es",
    path: subscriptions863dERUfdqMeta?.path ?? "/es/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___de",
    path: subscriptions863dERUfdqMeta?.path ?? "/de/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___fr",
    path: subscriptions863dERUfdqMeta?.path ?? "/fr/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___it",
    path: subscriptions863dERUfdqMeta?.path ?? "/it/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___he",
    path: subscriptions863dERUfdqMeta?.path ?? "/he/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___hu",
    path: subscriptions863dERUfdqMeta?.path ?? "/hu/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___nl",
    path: subscriptions863dERUfdqMeta?.path ?? "/nl/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptions863dERUfdqMeta?.name ?? "account-subscriptions___uk-UA",
    path: subscriptions863dERUfdqMeta?.path ?? "/uk-UA/account/subscriptions",
    meta: subscriptions863dERUfdqMeta || {},
    alias: subscriptions863dERUfdqMeta?.alias || [],
    redirect: subscriptions863dERUfdqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___en___default",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___en___default",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___en___default",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___en___default",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___en___default",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/en/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___en",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___en",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___en",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___en",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___en",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/es/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___es",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___es",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___es",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___es",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___es",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/de/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___de",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___de",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___de",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___de",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___de",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/fr/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___fr",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___fr",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___fr",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___fr",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___fr",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/it/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___it",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___it",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___it",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___it",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___it",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/he/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___he",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___he",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___he",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___he",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___he",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/hu/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___hu",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___hu",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___hu",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___hu",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___hu",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/nl/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___nl",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___nl",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___nl",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___nl",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___nl",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MpQx00LAF4Meta?.name ?? undefined,
    path: _91athleteId_93MpQx00LAF4Meta?.path ?? "/uk-UA/athletes/:athleteId()",
    meta: _91athleteId_93MpQx00LAF4Meta || {},
    alias: _91athleteId_93MpQx00LAF4Meta?.alias || [],
    redirect: _91athleteId_93MpQx00LAF4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId].vue").then(m => m.default || m),
    children: [
  {
    name: bio8QuB7WX0aSMeta?.name ?? "athletes-athleteId-bio___uk-UA",
    path: bio8QuB7WX0aSMeta?.path ?? "bio",
    meta: bio8QuB7WX0aSMeta || {},
    alias: bio8QuB7WX0aSMeta?.alias || [],
    redirect: bio8QuB7WX0aSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/bio.vue").then(m => m.default || m)
  },
  {
    name: chartszXwukM6VTkMeta?.name ?? "athletes-athleteId-charts___uk-UA",
    path: chartszXwukM6VTkMeta?.path ?? "charts",
    meta: chartszXwukM6VTkMeta || {},
    alias: chartszXwukM6VTkMeta?.alias || [],
    redirect: chartszXwukM6VTkMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/charts.vue").then(m => m.default || m)
  },
  {
    name: eventsEPlpvZC8LKMeta?.name ?? "athletes-athleteId-events___uk-UA",
    path: eventsEPlpvZC8LKMeta?.path ?? "events",
    meta: eventsEPlpvZC8LKMeta || {},
    alias: eventsEPlpvZC8LKMeta?.alias || [],
    redirect: eventsEPlpvZC8LKMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexM7Et5XBMBhMeta?.name ?? "athletes-athleteId___uk-UA",
    path: indexM7Et5XBMBhMeta?.path ?? "",
    meta: indexM7Et5XBMBhMeta || {},
    alias: indexM7Et5XBMBhMeta?.alias || [],
    redirect: indexM7Et5XBMBhMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/index.vue").then(m => m.default || m)
  },
  {
    name: statsHK2j1IHx3gMeta?.name ?? "athletes-athleteId-stats___uk-UA",
    path: statsHK2j1IHx3gMeta?.path ?? "stats",
    meta: statsHK2j1IHx3gMeta || {},
    alias: statsHK2j1IHx3gMeta?.alias || [],
    redirect: statsHK2j1IHx3gMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/[athleteId]/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___en___default",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___en",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/en/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___es",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/es/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___de",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/de/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___fr",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/fr/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___it",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/it/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___he",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/he/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___hu",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/hu/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___nl",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/nl/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91athleteId_93MDdTPBNrJFMeta?.name ?? "athletes-edit-athleteId___uk-UA",
    path: _91athleteId_93MDdTPBNrJFMeta?.path ?? "/uk-UA/athletes/edit/:athleteId()",
    meta: _91athleteId_93MDdTPBNrJFMeta || {},
    alias: _91athleteId_93MDdTPBNrJFMeta?.alias || [],
    redirect: _91athleteId_93MDdTPBNrJFMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/edit/[athleteId].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___en___default",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___en",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/en/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___es",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/es/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___de",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/de/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___fr",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/fr/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___it",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/it/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___he",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/he/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___hu",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/hu/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___nl",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/nl/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93vOnhWyGQyRMeta?.name ?? "athletes-events-type___uk-UA",
    path: _91type_93vOnhWyGQyRMeta?.path ?? "/uk-UA/athletes/events/:type()",
    meta: _91type_93vOnhWyGQyRMeta || {},
    alias: _91type_93vOnhWyGQyRMeta?.alias || [],
    redirect: _91type_93vOnhWyGQyRMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/events/[type].vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___en___default",
    path: indexj8xBrAC07YMeta?.path ?? "/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___en",
    path: indexj8xBrAC07YMeta?.path ?? "/en/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___es",
    path: indexj8xBrAC07YMeta?.path ?? "/es/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___de",
    path: indexj8xBrAC07YMeta?.path ?? "/de/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___fr",
    path: indexj8xBrAC07YMeta?.path ?? "/fr/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___it",
    path: indexj8xBrAC07YMeta?.path ?? "/it/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___he",
    path: indexj8xBrAC07YMeta?.path ?? "/he/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___hu",
    path: indexj8xBrAC07YMeta?.path ?? "/hu/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___nl",
    path: indexj8xBrAC07YMeta?.path ?? "/nl/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8xBrAC07YMeta?.name ?? "athletes___uk-UA",
    path: indexj8xBrAC07YMeta?.path ?? "/uk-UA/athletes",
    meta: indexj8xBrAC07YMeta || {},
    alias: indexj8xBrAC07YMeta?.alias || [],
    redirect: indexj8xBrAC07YMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/index.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___en___default",
    path: mergeVJVzwyCBR3Meta?.path ?? "/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___en",
    path: mergeVJVzwyCBR3Meta?.path ?? "/en/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___es",
    path: mergeVJVzwyCBR3Meta?.path ?? "/es/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___de",
    path: mergeVJVzwyCBR3Meta?.path ?? "/de/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___fr",
    path: mergeVJVzwyCBR3Meta?.path ?? "/fr/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___it",
    path: mergeVJVzwyCBR3Meta?.path ?? "/it/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___he",
    path: mergeVJVzwyCBR3Meta?.path ?? "/he/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___hu",
    path: mergeVJVzwyCBR3Meta?.path ?? "/hu/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___nl",
    path: mergeVJVzwyCBR3Meta?.path ?? "/nl/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: mergeVJVzwyCBR3Meta?.name ?? "athletes-merge___uk-UA",
    path: mergeVJVzwyCBR3Meta?.path ?? "/uk-UA/athletes/merge",
    meta: mergeVJVzwyCBR3Meta || {},
    alias: mergeVJVzwyCBR3Meta?.alias || [],
    redirect: mergeVJVzwyCBR3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/merge.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___en___default",
    path: newNIeIa3m8TiMeta?.path ?? "/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___en",
    path: newNIeIa3m8TiMeta?.path ?? "/en/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___es",
    path: newNIeIa3m8TiMeta?.path ?? "/es/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___de",
    path: newNIeIa3m8TiMeta?.path ?? "/de/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___fr",
    path: newNIeIa3m8TiMeta?.path ?? "/fr/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___it",
    path: newNIeIa3m8TiMeta?.path ?? "/it/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___he",
    path: newNIeIa3m8TiMeta?.path ?? "/he/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___hu",
    path: newNIeIa3m8TiMeta?.path ?? "/hu/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___nl",
    path: newNIeIa3m8TiMeta?.path ?? "/nl/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: newNIeIa3m8TiMeta?.name ?? "athletes-new___uk-UA",
    path: newNIeIa3m8TiMeta?.path ?? "/uk-UA/athletes/new",
    meta: newNIeIa3m8TiMeta || {},
    alias: newNIeIa3m8TiMeta?.alias || [],
    redirect: newNIeIa3m8TiMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/athletes/new.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___en___default",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___en",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/en/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___es",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/es/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___de",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/de/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___fr",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/fr/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___it",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/it/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___he",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/he/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___hu",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/hu/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___nl",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/nl/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryh0lAtQ3unIMeta?.name ?? "auth-email-recovery___uk-UA",
    path: recoveryh0lAtQ3unIMeta?.path ?? "/uk-UA/auth/email/recovery",
    meta: recoveryh0lAtQ3unIMeta || {},
    alias: recoveryh0lAtQ3unIMeta?.alias || [],
    redirect: recoveryh0lAtQ3unIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/recovery.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___en___default",
    path: verificationSSJ0RGyxceMeta?.path ?? "/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___en",
    path: verificationSSJ0RGyxceMeta?.path ?? "/en/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___es",
    path: verificationSSJ0RGyxceMeta?.path ?? "/es/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___de",
    path: verificationSSJ0RGyxceMeta?.path ?? "/de/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___fr",
    path: verificationSSJ0RGyxceMeta?.path ?? "/fr/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___it",
    path: verificationSSJ0RGyxceMeta?.path ?? "/it/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___he",
    path: verificationSSJ0RGyxceMeta?.path ?? "/he/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___hu",
    path: verificationSSJ0RGyxceMeta?.path ?? "/hu/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___nl",
    path: verificationSSJ0RGyxceMeta?.path ?? "/nl/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationSSJ0RGyxceMeta?.name ?? "auth-email-verification___uk-UA",
    path: verificationSSJ0RGyxceMeta?.path ?? "/uk-UA/auth/email/verification",
    meta: verificationSSJ0RGyxceMeta || {},
    alias: verificationSSJ0RGyxceMeta?.alias || [],
    redirect: verificationSSJ0RGyxceMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/email/verification.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___en___default",
    path: organizationjzRGSL5tccMeta?.path ?? "/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___en",
    path: organizationjzRGSL5tccMeta?.path ?? "/en/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___es",
    path: organizationjzRGSL5tccMeta?.path ?? "/es/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___de",
    path: organizationjzRGSL5tccMeta?.path ?? "/de/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___fr",
    path: organizationjzRGSL5tccMeta?.path ?? "/fr/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___it",
    path: organizationjzRGSL5tccMeta?.path ?? "/it/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___he",
    path: organizationjzRGSL5tccMeta?.path ?? "/he/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___hu",
    path: organizationjzRGSL5tccMeta?.path ?? "/hu/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___nl",
    path: organizationjzRGSL5tccMeta?.path ?? "/nl/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationjzRGSL5tccMeta?.name ?? "auth-invite-organization___uk-UA",
    path: organizationjzRGSL5tccMeta?.path ?? "/uk-UA/auth/invite/organization",
    meta: organizationjzRGSL5tccMeta || {},
    alias: organizationjzRGSL5tccMeta?.alias || [],
    redirect: organizationjzRGSL5tccMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/organization.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___en___default",
    path: teamDNdOGNJoKOMeta?.path ?? "/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___en",
    path: teamDNdOGNJoKOMeta?.path ?? "/en/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___es",
    path: teamDNdOGNJoKOMeta?.path ?? "/es/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___de",
    path: teamDNdOGNJoKOMeta?.path ?? "/de/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___fr",
    path: teamDNdOGNJoKOMeta?.path ?? "/fr/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___it",
    path: teamDNdOGNJoKOMeta?.path ?? "/it/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___he",
    path: teamDNdOGNJoKOMeta?.path ?? "/he/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___hu",
    path: teamDNdOGNJoKOMeta?.path ?? "/hu/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___nl",
    path: teamDNdOGNJoKOMeta?.path ?? "/nl/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: teamDNdOGNJoKOMeta?.name ?? "auth-invite-team___uk-UA",
    path: teamDNdOGNJoKOMeta?.path ?? "/uk-UA/auth/invite/team",
    meta: teamDNdOGNJoKOMeta || {},
    alias: teamDNdOGNJoKOMeta?.alias || [],
    redirect: teamDNdOGNJoKOMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/invite/team.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___en___default",
    path: loginta69GBK0H4Meta?.path ?? "/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___en",
    path: loginta69GBK0H4Meta?.path ?? "/en/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___es",
    path: loginta69GBK0H4Meta?.path ?? "/es/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___de",
    path: loginta69GBK0H4Meta?.path ?? "/de/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___fr",
    path: loginta69GBK0H4Meta?.path ?? "/fr/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___it",
    path: loginta69GBK0H4Meta?.path ?? "/it/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___he",
    path: loginta69GBK0H4Meta?.path ?? "/he/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___hu",
    path: loginta69GBK0H4Meta?.path ?? "/hu/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___nl",
    path: loginta69GBK0H4Meta?.path ?? "/nl/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginta69GBK0H4Meta?.name ?? "auth-login___uk-UA",
    path: loginta69GBK0H4Meta?.path ?? "/uk-UA/auth/login",
    meta: loginta69GBK0H4Meta || {},
    alias: loginta69GBK0H4Meta?.alias || [],
    redirect: loginta69GBK0H4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___en___default",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___en",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/en/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___es",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/es/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___de",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/de/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___fr",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/fr/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___it",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/it/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___he",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/he/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___hu",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/hu/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___nl",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/nl/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgot_45mail_45sentFXXRzy35RPMeta?.name ?? "auth-password-forgot-mail-sent___uk-UA",
    path: forgot_45mail_45sentFXXRzy35RPMeta?.path ?? "/uk-UA/auth/password/forgot-mail-sent",
    meta: forgot_45mail_45sentFXXRzy35RPMeta || {},
    alias: forgot_45mail_45sentFXXRzy35RPMeta?.alias || [],
    redirect: forgot_45mail_45sentFXXRzy35RPMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot-mail-sent.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___en___default",
    path: forgotwjsABlfmeBMeta?.path ?? "/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___en",
    path: forgotwjsABlfmeBMeta?.path ?? "/en/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___es",
    path: forgotwjsABlfmeBMeta?.path ?? "/es/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___de",
    path: forgotwjsABlfmeBMeta?.path ?? "/de/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___fr",
    path: forgotwjsABlfmeBMeta?.path ?? "/fr/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___it",
    path: forgotwjsABlfmeBMeta?.path ?? "/it/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___he",
    path: forgotwjsABlfmeBMeta?.path ?? "/he/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___hu",
    path: forgotwjsABlfmeBMeta?.path ?? "/hu/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___nl",
    path: forgotwjsABlfmeBMeta?.path ?? "/nl/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotwjsABlfmeBMeta?.name ?? "auth-password-forgot___uk-UA",
    path: forgotwjsABlfmeBMeta?.path ?? "/uk-UA/auth/password/forgot",
    meta: forgotwjsABlfmeBMeta || {},
    alias: forgotwjsABlfmeBMeta?.alias || [],
    redirect: forgotwjsABlfmeBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___en___default",
    path: resetDAFcvkEit4Meta?.path ?? "/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___en",
    path: resetDAFcvkEit4Meta?.path ?? "/en/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___es",
    path: resetDAFcvkEit4Meta?.path ?? "/es/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___de",
    path: resetDAFcvkEit4Meta?.path ?? "/de/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___fr",
    path: resetDAFcvkEit4Meta?.path ?? "/fr/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___it",
    path: resetDAFcvkEit4Meta?.path ?? "/it/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___he",
    path: resetDAFcvkEit4Meta?.path ?? "/he/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___hu",
    path: resetDAFcvkEit4Meta?.path ?? "/hu/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___nl",
    path: resetDAFcvkEit4Meta?.path ?? "/nl/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: resetDAFcvkEit4Meta?.name ?? "auth-password-reset___uk-UA",
    path: resetDAFcvkEit4Meta?.path ?? "/uk-UA/auth/password/reset",
    meta: resetDAFcvkEit4Meta || {},
    alias: resetDAFcvkEit4Meta?.alias || [],
    redirect: resetDAFcvkEit4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___en___default",
    path: redirect2w0y8fAVwZMeta?.path ?? "/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___en",
    path: redirect2w0y8fAVwZMeta?.path ?? "/en/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___es",
    path: redirect2w0y8fAVwZMeta?.path ?? "/es/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___de",
    path: redirect2w0y8fAVwZMeta?.path ?? "/de/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___fr",
    path: redirect2w0y8fAVwZMeta?.path ?? "/fr/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___it",
    path: redirect2w0y8fAVwZMeta?.path ?? "/it/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___he",
    path: redirect2w0y8fAVwZMeta?.path ?? "/he/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___hu",
    path: redirect2w0y8fAVwZMeta?.path ?? "/hu/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___nl",
    path: redirect2w0y8fAVwZMeta?.path ?? "/nl/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect2w0y8fAVwZMeta?.name ?? "auth-redirect___uk-UA",
    path: redirect2w0y8fAVwZMeta?.path ?? "/uk-UA/auth/redirect",
    meta: redirect2w0y8fAVwZMeta || {},
    alias: redirect2w0y8fAVwZMeta?.alias || [],
    redirect: redirect2w0y8fAVwZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___en___default",
    path: indexMGPlWhHvGpMeta?.path ?? "/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___en",
    path: indexMGPlWhHvGpMeta?.path ?? "/en/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___es",
    path: indexMGPlWhHvGpMeta?.path ?? "/es/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___de",
    path: indexMGPlWhHvGpMeta?.path ?? "/de/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___fr",
    path: indexMGPlWhHvGpMeta?.path ?? "/fr/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___it",
    path: indexMGPlWhHvGpMeta?.path ?? "/it/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___he",
    path: indexMGPlWhHvGpMeta?.path ?? "/he/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___hu",
    path: indexMGPlWhHvGpMeta?.path ?? "/hu/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___nl",
    path: indexMGPlWhHvGpMeta?.path ?? "/nl/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexMGPlWhHvGpMeta?.name ?? "auth-sign-up___uk-UA",
    path: indexMGPlWhHvGpMeta?.path ?? "/uk-UA/auth/sign-up",
    meta: indexMGPlWhHvGpMeta || {},
    alias: indexMGPlWhHvGpMeta?.alias || [],
    redirect: indexMGPlWhHvGpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___en___default",
    path: localoCRac2QUYtMeta?.path ?? "/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___en",
    path: localoCRac2QUYtMeta?.path ?? "/en/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___es",
    path: localoCRac2QUYtMeta?.path ?? "/es/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___de",
    path: localoCRac2QUYtMeta?.path ?? "/de/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___fr",
    path: localoCRac2QUYtMeta?.path ?? "/fr/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___it",
    path: localoCRac2QUYtMeta?.path ?? "/it/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___he",
    path: localoCRac2QUYtMeta?.path ?? "/he/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___hu",
    path: localoCRac2QUYtMeta?.path ?? "/hu/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___nl",
    path: localoCRac2QUYtMeta?.path ?? "/nl/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: localoCRac2QUYtMeta?.name ?? "auth-sign-up-local___uk-UA",
    path: localoCRac2QUYtMeta?.path ?? "/uk-UA/auth/sign-up/local",
    meta: localoCRac2QUYtMeta || {},
    alias: localoCRac2QUYtMeta?.alias || [],
    redirect: localoCRac2QUYtMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___en___default",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___en___default",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___en___default",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___en___default",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___en___default",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___en___default",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___en___default",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/en/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___en",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___en",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___en",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___en",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___en",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___en",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___en",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/es/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___es",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___es",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___es",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___es",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___es",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___es",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___es",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/de/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___de",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___de",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___de",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___de",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___de",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___de",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___de",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/fr/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___fr",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___fr",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___fr",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___fr",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___fr",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___fr",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___fr",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/it/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___it",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___it",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___it",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___it",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___it",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___it",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___it",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/he/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___he",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___he",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___he",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___he",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___he",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___he",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___he",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/hu/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___hu",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___hu",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___hu",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___hu",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___hu",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___hu",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___hu",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/nl/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___nl",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___nl",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___nl",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___nl",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___nl",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___nl",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___nl",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93SBydYrmjCzMeta?.name ?? undefined,
    path: _91eventId_93SBydYrmjCzMeta?.path ?? "/uk-UA/events/:eventId()",
    meta: _91eventId_93SBydYrmjCzMeta || {},
    alias: _91eventId_93SBydYrmjCzMeta?.alias || [],
    redirect: _91eventId_93SBydYrmjCzMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId].vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_938uaShgaUDmMeta?.name ?? "events-eventId-slug___uk-UA",
    path: _91_46_46_46slug_938uaShgaUDmMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_938uaShgaUDmMeta || {},
    alias: _91_46_46_46slug_938uaShgaUDmMeta?.alias || [],
    redirect: _91_46_46_46slug_938uaShgaUDmMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: athlete_45stats7KaXkY4DUWMeta?.name ?? "events-eventId-athlete-stats___uk-UA",
    path: athlete_45stats7KaXkY4DUWMeta?.path ?? "athlete-stats",
    meta: athlete_45stats7KaXkY4DUWMeta || {},
    alias: athlete_45stats7KaXkY4DUWMeta?.alias || [],
    redirect: athlete_45stats7KaXkY4DUWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/athlete-stats.vue").then(m => m.default || m)
  },
  {
    name: _91type_93iIEVko11MSMeta?.name ?? "events-eventId-clips-type___uk-UA",
    path: _91type_93iIEVko11MSMeta?.path ?? "clips/:type()",
    meta: _91type_93iIEVko11MSMeta || {},
    alias: _91type_93iIEVko11MSMeta?.alias || [],
    redirect: _91type_93iIEVko11MSMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/[type].vue").then(m => m.default || m)
  },
  {
    name: indexvg1CtJ2Mr3Meta?.name ?? "events-eventId-clips___uk-UA",
    path: indexvg1CtJ2Mr3Meta?.path ?? "clips",
    meta: indexvg1CtJ2Mr3Meta || {},
    alias: indexvg1CtJ2Mr3Meta?.alias || [],
    redirect: indexvg1CtJ2Mr3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/clips/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7uKF2OGsIMeta?.name ?? "events-eventId___uk-UA",
    path: indexB7uKF2OGsIMeta?.path ?? "",
    meta: indexB7uKF2OGsIMeta || {},
    alias: indexB7uKF2OGsIMeta?.alias || [],
    redirect: indexB7uKF2OGsIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: play_45by_45playZWcp1coIlpMeta?.name ?? "events-eventId-play-by-play___uk-UA",
    path: play_45by_45playZWcp1coIlpMeta?.path ?? "play-by-play",
    meta: play_45by_45playZWcp1coIlpMeta || {},
    alias: play_45by_45playZWcp1coIlpMeta?.alias || [],
    redirect: play_45by_45playZWcp1coIlpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/play-by-play.vue").then(m => m.default || m)
  },
  {
    name: team_45statsNmrxDu7bOIMeta?.name ?? "events-eventId-team-stats___uk-UA",
    path: team_45statsNmrxDu7bOIMeta?.path ?? "team-stats",
    meta: team_45statsNmrxDu7bOIMeta || {},
    alias: team_45statsNmrxDu7bOIMeta?.alias || [],
    redirect: team_45statsNmrxDu7bOIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/[eventId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___en___default",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___en",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/en/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___es",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/es/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___de",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/de/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___fr",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/fr/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___it",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/it/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___he",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/he/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___hu",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/hu/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___nl",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/nl/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93CQ5qBpYoTVMeta?.name ?? "events-edit-eventId___uk-UA",
    path: _91eventId_93CQ5qBpYoTVMeta?.path ?? "/uk-UA/events/edit/:eventId()",
    meta: _91eventId_93CQ5qBpYoTVMeta || {},
    alias: _91eventId_93CQ5qBpYoTVMeta?.alias || [],
    redirect: _91eventId_93CQ5qBpYoTVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/edit/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___en___default",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___en",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/en/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___es",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/es/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___de",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/de/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___fr",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/fr/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___it",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/it/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___he",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/he/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___hu",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/hu/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___nl",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/nl/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93VvIbKhlmDqMeta?.name ?? "events-editor-eventId___uk-UA",
    path: _91eventId_93VvIbKhlmDqMeta?.path ?? "/uk-UA/events/editor/:eventId()",
    meta: _91eventId_93VvIbKhlmDqMeta || {},
    alias: _91eventId_93VvIbKhlmDqMeta?.alias || [],
    redirect: _91eventId_93VvIbKhlmDqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/editor/[eventId].vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___en___default",
    path: index4MOAzubHXDMeta?.path ?? "/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___en",
    path: index4MOAzubHXDMeta?.path ?? "/en/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___es",
    path: index4MOAzubHXDMeta?.path ?? "/es/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___de",
    path: index4MOAzubHXDMeta?.path ?? "/de/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___fr",
    path: index4MOAzubHXDMeta?.path ?? "/fr/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___it",
    path: index4MOAzubHXDMeta?.path ?? "/it/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___he",
    path: index4MOAzubHXDMeta?.path ?? "/he/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___hu",
    path: index4MOAzubHXDMeta?.path ?? "/hu/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___nl",
    path: index4MOAzubHXDMeta?.path ?? "/nl/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index4MOAzubHXDMeta?.name ?? "events___uk-UA",
    path: index4MOAzubHXDMeta?.path ?? "/uk-UA/events",
    meta: index4MOAzubHXDMeta || {},
    alias: index4MOAzubHXDMeta?.alias || [],
    redirect: index4MOAzubHXDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___en___default",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___en",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/en/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___es",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/es/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___de",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/de/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___fr",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/fr/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___it",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/it/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___he",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/he/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___hu",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/hu/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___nl",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/nl/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93JrVFNsb9qEMeta?.name ?? "events-list-type___uk-UA",
    path: _91type_93JrVFNsb9qEMeta?.path ?? "/uk-UA/events/list/:type()",
    meta: _91type_93JrVFNsb9qEMeta || {},
    alias: _91type_93JrVFNsb9qEMeta?.alias || [],
    redirect: _91type_93JrVFNsb9qEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/events/list/[type].vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___en___default",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___en",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/en/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___es",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/es/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___de",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/de/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___fr",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/fr/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___it",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/it/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___he",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/he/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___hu",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/hu/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___nl",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/nl/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsheSXM8Q4RfMeta?.name ?? "highlights___uk-UA",
    path: highlightsheSXM8Q4RfMeta?.path ?? "/uk-UA/highlights",
    meta: highlightsheSXM8Q4RfMeta || {},
    alias: highlightsheSXM8Q4RfMeta?.alias || [],
    redirect: highlightsheSXM8Q4RfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___en___default",
    path: indexe6UbTgbNjoMeta?.path ?? "/",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___en",
    path: indexe6UbTgbNjoMeta?.path ?? "/en",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___es",
    path: indexe6UbTgbNjoMeta?.path ?? "/es",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___de",
    path: indexe6UbTgbNjoMeta?.path ?? "/de",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___fr",
    path: indexe6UbTgbNjoMeta?.path ?? "/fr",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___it",
    path: indexe6UbTgbNjoMeta?.path ?? "/it",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___he",
    path: indexe6UbTgbNjoMeta?.path ?? "/he",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___hu",
    path: indexe6UbTgbNjoMeta?.path ?? "/hu",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___nl",
    path: indexe6UbTgbNjoMeta?.path ?? "/nl",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe6UbTgbNjoMeta?.name ?? "index___uk-UA",
    path: indexe6UbTgbNjoMeta?.path ?? "/uk-UA",
    meta: indexe6UbTgbNjoMeta || {},
    alias: indexe6UbTgbNjoMeta?.alias || [],
    redirect: indexe6UbTgbNjoMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___en___default",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___en___default",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___en___default",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___en___default",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___en___default",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___en___default",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/en/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___en",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___en",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___en",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___en",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___en",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___en",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/es/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___es",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___es",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___es",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___es",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___es",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___es",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/de/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___de",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___de",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___de",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___de",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___de",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___de",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/fr/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___fr",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___fr",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___fr",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___fr",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___fr",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___fr",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/it/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___it",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___it",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___it",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___it",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___it",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___it",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/he/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___he",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___he",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___he",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___he",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___he",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___he",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/hu/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___hu",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___hu",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___hu",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___hu",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___hu",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___hu",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/nl/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___nl",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___nl",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___nl",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___nl",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___nl",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___nl",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93dsMyt5ck3uMeta?.name ?? undefined,
    path: _91leagueId_93dsMyt5ck3uMeta?.path ?? "/uk-UA/leagues/:leagueId()",
    meta: _91leagueId_93dsMyt5ck3uMeta || {},
    alias: _91leagueId_93dsMyt5ck3uMeta?.alias || [],
    redirect: _91leagueId_93dsMyt5ck3uMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesuapCKyEcLxMeta?.name ?? "leagues-leagueId-athletes___uk-UA",
    path: athletesuapCKyEcLxMeta?.path ?? "athletes",
    meta: athletesuapCKyEcLxMeta || {},
    alias: athletesuapCKyEcLxMeta?.alias || [],
    redirect: athletesuapCKyEcLxMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: eventsP8AnlVx2y6Meta?.name ?? "leagues-leagueId-events___uk-UA",
    path: eventsP8AnlVx2y6Meta?.path ?? "events",
    meta: eventsP8AnlVx2y6Meta || {},
    alias: eventsP8AnlVx2y6Meta?.alias || [],
    redirect: eventsP8AnlVx2y6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexL4NZOlR4FWMeta?.name ?? "leagues-leagueId___uk-UA",
    path: indexL4NZOlR4FWMeta?.path ?? "",
    meta: indexL4NZOlR4FWMeta || {},
    alias: indexL4NZOlR4FWMeta?.alias || [],
    redirect: indexL4NZOlR4FWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardskvf3PcnfK6Meta?.name ?? "leagues-leagueId-leaderboards___uk-UA",
    path: leaderboardskvf3PcnfK6Meta?.path ?? "leaderboards",
    meta: leaderboardskvf3PcnfK6Meta || {},
    alias: leaderboardskvf3PcnfK6Meta?.alias || [],
    redirect: leaderboardskvf3PcnfK6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/leaderboards.vue").then(m => m.default || m)
  },
  {
    name: standingswcm5RXSUBfMeta?.name ?? "leagues-leagueId-standings___uk-UA",
    path: standingswcm5RXSUBfMeta?.path ?? "standings",
    meta: standingswcm5RXSUBfMeta || {},
    alias: standingswcm5RXSUBfMeta?.alias || [],
    redirect: standingswcm5RXSUBfMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/standings.vue").then(m => m.default || m)
  },
  {
    name: teamsXyLY5DOeQ3Meta?.name ?? "leagues-leagueId-teams___uk-UA",
    path: teamsXyLY5DOeQ3Meta?.path ?? "teams",
    meta: teamsXyLY5DOeQ3Meta || {},
    alias: teamsXyLY5DOeQ3Meta?.alias || [],
    redirect: teamsXyLY5DOeQ3Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/[leagueId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___en___default",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___en",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/en/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___es",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/es/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___de",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/de/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___fr",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/fr/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___it",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/it/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___he",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/he/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___hu",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/hu/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___nl",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/nl/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93CMbkHF4976Meta?.name ?? "leagues-edit-leagueId___uk-UA",
    path: _91leagueId_93CMbkHF4976Meta?.path ?? "/uk-UA/leagues/edit/:leagueId()",
    meta: _91leagueId_93CMbkHF4976Meta || {},
    alias: _91leagueId_93CMbkHF4976Meta?.alias || [],
    redirect: _91leagueId_93CMbkHF4976Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/edit/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___en___default",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___en",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/en/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___es",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/es/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___de",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/de/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___fr",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/fr/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___it",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/it/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___he",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/he/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___hu",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/hu/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___nl",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/nl/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93zpiaaj6yyIMeta?.name ?? "leagues-events-type___uk-UA",
    path: _91type_93zpiaaj6yyIMeta?.path ?? "/uk-UA/leagues/events/:type()",
    meta: _91type_93zpiaaj6yyIMeta || {},
    alias: _91type_93zpiaaj6yyIMeta?.alias || [],
    redirect: _91type_93zpiaaj6yyIMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/events/[type].vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___en___default",
    path: indexPjpX9W4aiCMeta?.path ?? "/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___en",
    path: indexPjpX9W4aiCMeta?.path ?? "/en/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___es",
    path: indexPjpX9W4aiCMeta?.path ?? "/es/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___de",
    path: indexPjpX9W4aiCMeta?.path ?? "/de/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___fr",
    path: indexPjpX9W4aiCMeta?.path ?? "/fr/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___it",
    path: indexPjpX9W4aiCMeta?.path ?? "/it/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___he",
    path: indexPjpX9W4aiCMeta?.path ?? "/he/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___hu",
    path: indexPjpX9W4aiCMeta?.path ?? "/hu/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___nl",
    path: indexPjpX9W4aiCMeta?.path ?? "/nl/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjpX9W4aiCMeta?.name ?? "leagues___uk-UA",
    path: indexPjpX9W4aiCMeta?.path ?? "/uk-UA/leagues",
    meta: indexPjpX9W4aiCMeta || {},
    alias: indexPjpX9W4aiCMeta?.alias || [],
    redirect: indexPjpX9W4aiCMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/index.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___en___default",
    path: newU59wsGD6wBMeta?.path ?? "/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___en",
    path: newU59wsGD6wBMeta?.path ?? "/en/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___es",
    path: newU59wsGD6wBMeta?.path ?? "/es/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___de",
    path: newU59wsGD6wBMeta?.path ?? "/de/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___fr",
    path: newU59wsGD6wBMeta?.path ?? "/fr/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___it",
    path: newU59wsGD6wBMeta?.path ?? "/it/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___he",
    path: newU59wsGD6wBMeta?.path ?? "/he/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___hu",
    path: newU59wsGD6wBMeta?.path ?? "/hu/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___nl",
    path: newU59wsGD6wBMeta?.path ?? "/nl/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: newU59wsGD6wBMeta?.name ?? "leagues-new___uk-UA",
    path: newU59wsGD6wBMeta?.path ?? "/uk-UA/leagues/new",
    meta: newU59wsGD6wBMeta || {},
    alias: newU59wsGD6wBMeta?.alias || [],
    redirect: newU59wsGD6wBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/leagues/new.vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___en___default",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___en___default",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___en___default",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/en/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___en",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___en",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___en",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/es/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___es",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___es",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___es",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/de/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___de",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___de",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___de",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/fr/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___fr",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___fr",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___fr",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/it/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___it",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___it",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___it",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/he/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___he",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___he",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___he",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/hu/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___hu",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___hu",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___hu",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/nl/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___nl",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___nl",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___nl",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93swIkRPYbj9Meta?.name ?? undefined,
    path: _91organizationId_93swIkRPYbj9Meta?.path ?? "/uk-UA/organizations/:organizationId()",
    meta: _91organizationId_93swIkRPYbj9Meta || {},
    alias: _91organizationId_93swIkRPYbj9Meta?.alias || [],
    redirect: _91organizationId_93swIkRPYbj9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: adminsh2ERF2xo2mMeta?.name ?? "organizations-organizationId-admins___uk-UA",
    path: adminsh2ERF2xo2mMeta?.path ?? "admins",
    meta: adminsh2ERF2xo2mMeta || {},
    alias: adminsh2ERF2xo2mMeta?.alias || [],
    redirect: adminsh2ERF2xo2mMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/admins.vue").then(m => m.default || m)
  },
  {
    name: indexqXFCnvhRFpMeta?.name ?? "organizations-organizationId___uk-UA",
    path: indexqXFCnvhRFpMeta?.path ?? "",
    meta: indexqXFCnvhRFpMeta || {},
    alias: indexqXFCnvhRFpMeta?.alias || [],
    redirect: indexqXFCnvhRFpMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: teamsxleiuOTJMVMeta?.name ?? "organizations-organizationId-teams___uk-UA",
    path: teamsxleiuOTJMVMeta?.path ?? "teams",
    meta: teamsxleiuOTJMVMeta || {},
    alias: teamsxleiuOTJMVMeta?.alias || [],
    redirect: teamsxleiuOTJMVMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/[organizationId]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___en___default",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___en",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/en/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___es",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/es/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___de",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/de/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___fr",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/fr/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___it",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/it/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___he",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/he/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___hu",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/hu/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___nl",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/nl/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93wgdqRhjgHnMeta?.name ?? "organizations-edit-organizationId___uk-UA",
    path: _91organizationId_93wgdqRhjgHnMeta?.path ?? "/uk-UA/organizations/edit/:organizationId()",
    meta: _91organizationId_93wgdqRhjgHnMeta || {},
    alias: _91organizationId_93wgdqRhjgHnMeta?.alias || [],
    redirect: _91organizationId_93wgdqRhjgHnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/edit/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___en___default",
    path: indexgSIp5ICIKbMeta?.path ?? "/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___en",
    path: indexgSIp5ICIKbMeta?.path ?? "/en/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___es",
    path: indexgSIp5ICIKbMeta?.path ?? "/es/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___de",
    path: indexgSIp5ICIKbMeta?.path ?? "/de/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___fr",
    path: indexgSIp5ICIKbMeta?.path ?? "/fr/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___it",
    path: indexgSIp5ICIKbMeta?.path ?? "/it/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___he",
    path: indexgSIp5ICIKbMeta?.path ?? "/he/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___hu",
    path: indexgSIp5ICIKbMeta?.path ?? "/hu/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___nl",
    path: indexgSIp5ICIKbMeta?.path ?? "/nl/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSIp5ICIKbMeta?.name ?? "organizations___uk-UA",
    path: indexgSIp5ICIKbMeta?.path ?? "/uk-UA/organizations",
    meta: indexgSIp5ICIKbMeta || {},
    alias: indexgSIp5ICIKbMeta?.alias || [],
    redirect: indexgSIp5ICIKbMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___en___default",
    path: newP8qZuK9OEAMeta?.path ?? "/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___en",
    path: newP8qZuK9OEAMeta?.path ?? "/en/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___es",
    path: newP8qZuK9OEAMeta?.path ?? "/es/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___de",
    path: newP8qZuK9OEAMeta?.path ?? "/de/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___fr",
    path: newP8qZuK9OEAMeta?.path ?? "/fr/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___it",
    path: newP8qZuK9OEAMeta?.path ?? "/it/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___he",
    path: newP8qZuK9OEAMeta?.path ?? "/he/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___hu",
    path: newP8qZuK9OEAMeta?.path ?? "/hu/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___nl",
    path: newP8qZuK9OEAMeta?.path ?? "/nl/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: newP8qZuK9OEAMeta?.name ?? "organizations-new___uk-UA",
    path: newP8qZuK9OEAMeta?.path ?? "/uk-UA/organizations/new",
    meta: newP8qZuK9OEAMeta || {},
    alias: newP8qZuK9OEAMeta?.alias || [],
    redirect: newP8qZuK9OEAMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___en___default",
    path: practicesEO9tffz09LMeta?.path ?? "/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___en",
    path: practicesEO9tffz09LMeta?.path ?? "/en/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___es",
    path: practicesEO9tffz09LMeta?.path ?? "/es/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___de",
    path: practicesEO9tffz09LMeta?.path ?? "/de/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___fr",
    path: practicesEO9tffz09LMeta?.path ?? "/fr/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___it",
    path: practicesEO9tffz09LMeta?.path ?? "/it/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___he",
    path: practicesEO9tffz09LMeta?.path ?? "/he/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___hu",
    path: practicesEO9tffz09LMeta?.path ?? "/hu/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___nl",
    path: practicesEO9tffz09LMeta?.path ?? "/nl/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: practicesEO9tffz09LMeta?.name ?? "practices___uk-UA",
    path: practicesEO9tffz09LMeta?.path ?? "/uk-UA/practices",
    meta: practicesEO9tffz09LMeta || {},
    alias: practicesEO9tffz09LMeta?.alias || [],
    redirect: practicesEO9tffz09LMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/practices.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___en___default",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___en___default",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___en___default",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___en___default",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___en___default",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___en___default",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___en___default",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___en___default",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___en___default",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/en/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___en",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___en",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___en",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___en",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___en",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___en",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___en",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___en",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___en",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/es/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___es",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___es",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___es",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___es",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___es",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___es",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___es",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___es",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___es",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/de/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___de",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___de",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___de",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___de",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___de",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___de",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___de",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___de",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___de",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/fr/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___fr",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___fr",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___fr",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___fr",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___fr",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___fr",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___fr",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___fr",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___fr",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/it/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___it",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___it",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___it",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___it",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___it",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___it",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___it",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___it",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___it",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/he/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___he",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___he",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___he",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___he",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___he",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___he",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___he",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___he",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___he",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/hu/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___hu",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___hu",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___hu",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___hu",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___hu",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___hu",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___hu",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___hu",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___hu",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/nl/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___nl",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___nl",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___nl",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___nl",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___nl",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___nl",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___nl",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___nl",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___nl",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91teamId_93i1OEOfAKDgMeta?.name ?? undefined,
    path: _91teamId_93i1OEOfAKDgMeta?.path ?? "/uk-UA/teams/:teamId()",
    meta: _91teamId_93i1OEOfAKDgMeta || {},
    alias: _91teamId_93i1OEOfAKDgMeta?.alias || [],
    redirect: _91teamId_93i1OEOfAKDgMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: athletesMyQaoxpW3MMeta?.name ?? "teams-teamId-athletes___uk-UA",
    path: athletesMyQaoxpW3MMeta?.path ?? "athletes",
    meta: athletesMyQaoxpW3MMeta || {},
    alias: athletesMyQaoxpW3MMeta?.alias || [],
    redirect: athletesMyQaoxpW3MMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/athletes.vue").then(m => m.default || m)
  },
  {
    name: breakdowns_45and_45servicesk0QWfKO5d5Meta?.name ?? "teams-teamId-breakdowns-and-services___uk-UA",
    path: breakdowns_45and_45servicesk0QWfKO5d5Meta?.path ?? "breakdowns-and-services",
    meta: breakdowns_45and_45servicesk0QWfKO5d5Meta || {},
    alias: breakdowns_45and_45servicesk0QWfKO5d5Meta?.alias || [],
    redirect: breakdowns_45and_45servicesk0QWfKO5d5Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/breakdowns-and-services.vue").then(m => m.default || m)
  },
  {
    name: coacheskunf4PyjkHMeta?.name ?? "teams-teamId-coaches___uk-UA",
    path: coacheskunf4PyjkHMeta?.path ?? "coaches",
    meta: coacheskunf4PyjkHMeta || {},
    alias: coacheskunf4PyjkHMeta?.alias || [],
    redirect: coacheskunf4PyjkHMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/coaches.vue").then(m => m.default || m)
  },
  {
    name: events34fKnJWzjDMeta?.name ?? "teams-teamId-events___uk-UA",
    path: events34fKnJWzjDMeta?.path ?? "events",
    meta: events34fKnJWzjDMeta || {},
    alias: events34fKnJWzjDMeta?.alias || [],
    redirect: events34fKnJWzjDMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/events.vue").then(m => m.default || m)
  },
  {
    name: indexHIzgQAzyx4Meta?.name ?? "teams-teamId___uk-UA",
    path: indexHIzgQAzyx4Meta?.path ?? "",
    meta: indexHIzgQAzyx4Meta || {},
    alias: indexHIzgQAzyx4Meta?.alias || [],
    redirect: indexHIzgQAzyx4Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: membersxV92Gm8jWYMeta?.name ?? "teams-teamId-members___uk-UA",
    path: membersxV92Gm8jWYMeta?.path ?? "members",
    meta: membersxV92Gm8jWYMeta || {},
    alias: membersxV92Gm8jWYMeta?.alias || [],
    redirect: membersxV92Gm8jWYMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/members.vue").then(m => m.default || m)
  },
  {
    name: opponentseek8po5eX9Meta?.name ?? "teams-teamId-opponents___uk-UA",
    path: opponentseek8po5eX9Meta?.path ?? "opponents",
    meta: opponentseek8po5eX9Meta || {},
    alias: opponentseek8po5eX9Meta?.alias || [],
    redirect: opponentseek8po5eX9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/opponents.vue").then(m => m.default || m)
  },
  {
    name: rostersl7DpkzxW3ZMeta?.name ?? "teams-teamId-rosters___uk-UA",
    path: rostersl7DpkzxW3ZMeta?.path ?? "rosters",
    meta: rostersl7DpkzxW3ZMeta || {},
    alias: rostersl7DpkzxW3ZMeta?.alias || [],
    redirect: rostersl7DpkzxW3ZMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/rosters.vue").then(m => m.default || m)
  },
  {
    name: team_45statsVqtpS7gCQGMeta?.name ?? "teams-teamId-team-stats___uk-UA",
    path: team_45statsVqtpS7gCQGMeta?.path ?? "team-stats",
    meta: team_45statsVqtpS7gCQGMeta || {},
    alias: team_45statsVqtpS7gCQGMeta?.alias || [],
    redirect: team_45statsVqtpS7gCQGMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/[teamId]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___en___default",
    path: editUy4StJoNn6Meta?.path ?? "/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___en",
    path: editUy4StJoNn6Meta?.path ?? "/en/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___es",
    path: editUy4StJoNn6Meta?.path ?? "/es/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___de",
    path: editUy4StJoNn6Meta?.path ?? "/de/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___fr",
    path: editUy4StJoNn6Meta?.path ?? "/fr/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___it",
    path: editUy4StJoNn6Meta?.path ?? "/it/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___he",
    path: editUy4StJoNn6Meta?.path ?? "/he/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___hu",
    path: editUy4StJoNn6Meta?.path ?? "/hu/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___nl",
    path: editUy4StJoNn6Meta?.path ?? "/nl/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editUy4StJoNn6Meta?.name ?? "teams-athletes-teamId-athleteId-edit___uk-UA",
    path: editUy4StJoNn6Meta?.path ?? "/uk-UA/teams/athletes/:teamId()/:athleteId()/edit",
    meta: editUy4StJoNn6Meta || {},
    alias: editUy4StJoNn6Meta?.alias || [],
    redirect: editUy4StJoNn6Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/[athleteId]/edit.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___en___default",
    path: newdwVGajkUGeMeta?.path ?? "/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___en",
    path: newdwVGajkUGeMeta?.path ?? "/en/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___es",
    path: newdwVGajkUGeMeta?.path ?? "/es/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___de",
    path: newdwVGajkUGeMeta?.path ?? "/de/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___fr",
    path: newdwVGajkUGeMeta?.path ?? "/fr/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___it",
    path: newdwVGajkUGeMeta?.path ?? "/it/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___he",
    path: newdwVGajkUGeMeta?.path ?? "/he/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___hu",
    path: newdwVGajkUGeMeta?.path ?? "/hu/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___nl",
    path: newdwVGajkUGeMeta?.path ?? "/nl/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: newdwVGajkUGeMeta?.name ?? "teams-athletes-teamId-new___uk-UA",
    path: newdwVGajkUGeMeta?.path ?? "/uk-UA/teams/athletes/:teamId()/new",
    meta: newdwVGajkUGeMeta || {},
    alias: newdwVGajkUGeMeta?.alias || [],
    redirect: newdwVGajkUGeMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/athletes/[teamId]/new.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___en___default",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___en",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/en/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___es",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/es/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___de",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/de/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___fr",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/fr/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___it",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/it/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___he",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/he/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___hu",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/hu/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___nl",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/nl/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93qU7FIp2L6nMeta?.name ?? "teams-edit-teamId___uk-UA",
    path: _91teamId_93qU7FIp2L6nMeta?.path ?? "/uk-UA/teams/edit/:teamId()",
    meta: _91teamId_93qU7FIp2L6nMeta || {},
    alias: _91teamId_93qU7FIp2L6nMeta?.alias || [],
    redirect: _91teamId_93qU7FIp2L6nMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/edit/[teamId].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___en___default",
    path: _91type_93e569rP5ofEMeta?.path ?? "/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___en",
    path: _91type_93e569rP5ofEMeta?.path ?? "/en/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___es",
    path: _91type_93e569rP5ofEMeta?.path ?? "/es/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___de",
    path: _91type_93e569rP5ofEMeta?.path ?? "/de/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___fr",
    path: _91type_93e569rP5ofEMeta?.path ?? "/fr/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___it",
    path: _91type_93e569rP5ofEMeta?.path ?? "/it/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___he",
    path: _91type_93e569rP5ofEMeta?.path ?? "/he/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___hu",
    path: _91type_93e569rP5ofEMeta?.path ?? "/hu/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___nl",
    path: _91type_93e569rP5ofEMeta?.path ?? "/nl/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93e569rP5ofEMeta?.name ?? "teams-events-type___uk-UA",
    path: _91type_93e569rP5ofEMeta?.path ?? "/uk-UA/teams/events/:type()",
    meta: _91type_93e569rP5ofEMeta || {},
    alias: _91type_93e569rP5ofEMeta?.alias || [],
    redirect: _91type_93e569rP5ofEMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/events/[type].vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___en___default",
    path: indexJBJFM5lJUqMeta?.path ?? "/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___en",
    path: indexJBJFM5lJUqMeta?.path ?? "/en/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___es",
    path: indexJBJFM5lJUqMeta?.path ?? "/es/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___de",
    path: indexJBJFM5lJUqMeta?.path ?? "/de/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___fr",
    path: indexJBJFM5lJUqMeta?.path ?? "/fr/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___it",
    path: indexJBJFM5lJUqMeta?.path ?? "/it/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___he",
    path: indexJBJFM5lJUqMeta?.path ?? "/he/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___hu",
    path: indexJBJFM5lJUqMeta?.path ?? "/hu/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___nl",
    path: indexJBJFM5lJUqMeta?.path ?? "/nl/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBJFM5lJUqMeta?.name ?? "teams___uk-UA",
    path: indexJBJFM5lJUqMeta?.path ?? "/uk-UA/teams",
    meta: indexJBJFM5lJUqMeta || {},
    alias: indexJBJFM5lJUqMeta?.alias || [],
    redirect: indexJBJFM5lJUqMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___en___default",
    path: joinckEjZ8DBe9Meta?.path ?? "/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___en",
    path: joinckEjZ8DBe9Meta?.path ?? "/en/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___es",
    path: joinckEjZ8DBe9Meta?.path ?? "/es/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___de",
    path: joinckEjZ8DBe9Meta?.path ?? "/de/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___fr",
    path: joinckEjZ8DBe9Meta?.path ?? "/fr/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___it",
    path: joinckEjZ8DBe9Meta?.path ?? "/it/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___he",
    path: joinckEjZ8DBe9Meta?.path ?? "/he/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___hu",
    path: joinckEjZ8DBe9Meta?.path ?? "/hu/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___nl",
    path: joinckEjZ8DBe9Meta?.path ?? "/nl/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: joinckEjZ8DBe9Meta?.name ?? "teams-join___uk-UA",
    path: joinckEjZ8DBe9Meta?.path ?? "/uk-UA/teams/join",
    meta: joinckEjZ8DBe9Meta || {},
    alias: joinckEjZ8DBe9Meta?.alias || [],
    redirect: joinckEjZ8DBe9Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/join.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___en___default",
    path: newJRHKHTUGq7Meta?.path ?? "/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___en",
    path: newJRHKHTUGq7Meta?.path ?? "/en/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___es",
    path: newJRHKHTUGq7Meta?.path ?? "/es/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___de",
    path: newJRHKHTUGq7Meta?.path ?? "/de/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___fr",
    path: newJRHKHTUGq7Meta?.path ?? "/fr/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___it",
    path: newJRHKHTUGq7Meta?.path ?? "/it/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___he",
    path: newJRHKHTUGq7Meta?.path ?? "/he/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___hu",
    path: newJRHKHTUGq7Meta?.path ?? "/hu/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___nl",
    path: newJRHKHTUGq7Meta?.path ?? "/nl/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: newJRHKHTUGq7Meta?.name ?? "teams-new___uk-UA",
    path: newJRHKHTUGq7Meta?.path ?? "/uk-UA/teams/new",
    meta: newJRHKHTUGq7Meta || {},
    alias: newJRHKHTUGq7Meta?.alias || [],
    redirect: newJRHKHTUGq7Meta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/new.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___en___default",
    path: index9DYMJpYyoWMeta?.path ?? "/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___en",
    path: index9DYMJpYyoWMeta?.path ?? "/en/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___es",
    path: index9DYMJpYyoWMeta?.path ?? "/es/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___de",
    path: index9DYMJpYyoWMeta?.path ?? "/de/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___fr",
    path: index9DYMJpYyoWMeta?.path ?? "/fr/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___it",
    path: index9DYMJpYyoWMeta?.path ?? "/it/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___he",
    path: index9DYMJpYyoWMeta?.path ?? "/he/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___hu",
    path: index9DYMJpYyoWMeta?.path ?? "/hu/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___nl",
    path: index9DYMJpYyoWMeta?.path ?? "/nl/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9DYMJpYyoWMeta?.name ?? "teams-upload-teamId___uk-UA",
    path: index9DYMJpYyoWMeta?.path ?? "/uk-UA/teams/upload/:teamId()",
    meta: index9DYMJpYyoWMeta || {},
    alias: index9DYMJpYyoWMeta?.alias || [],
    redirect: index9DYMJpYyoWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___en___default",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___en",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/en/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___es",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/es/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___de",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/de/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___fr",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/fr/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___it",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/it/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___he",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/he/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___hu",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/hu/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___nl",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/nl/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: pixellotRxN9JKTBJBMeta?.name ?? "teams-upload-teamId-pixellot___uk-UA",
    path: pixellotRxN9JKTBJBMeta?.path ?? "/uk-UA/teams/upload/:teamId()/pixellot",
    meta: pixellotRxN9JKTBJBMeta || {},
    alias: pixellotRxN9JKTBJBMeta?.alias || [],
    redirect: pixellotRxN9JKTBJBMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/teams/upload/[teamId]/pixellot.vue").then(m => m.default || m)
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___en___default",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___en___default",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/en/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___en",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___en",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/es/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___es",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___es",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/de/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___de",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___de",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/fr/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___fr",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___fr",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/it/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___it",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___it",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/he/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___he",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___he",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/hu/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___hu",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___hu",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/nl/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___nl",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___nl",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contentK6rC5vzLgnMeta?.name ?? undefined,
    path: contentK6rC5vzLgnMeta?.path ?? "/uk-UA/user/content",
    meta: contentK6rC5vzLgnMeta || {},
    alias: contentK6rC5vzLgnMeta?.alias || [],
    redirect: contentK6rC5vzLgnMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content.vue").then(m => m.default || m),
    children: [
  {
    name: favoritestl83H27JbuMeta?.name ?? "user-content-favorites___uk-UA",
    path: favoritestl83H27JbuMeta?.path ?? "favorites",
    meta: favoritestl83H27JbuMeta || {},
    alias: favoritestl83H27JbuMeta?.alias || [],
    redirect: favoritestl83H27JbuMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexxPfRnHz6fJMeta?.name ?? "user-content___uk-UA",
    path: indexxPfRnHz6fJMeta?.path ?? "",
    meta: indexxPfRnHz6fJMeta || {},
    alias: indexxPfRnHz6fJMeta?.alias || [],
    redirect: indexxPfRnHz6fJMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/content/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___en___default",
    path: profileghxNXdNo6qMeta?.path ?? "/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___en",
    path: profileghxNXdNo6qMeta?.path ?? "/en/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___es",
    path: profileghxNXdNo6qMeta?.path ?? "/es/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___de",
    path: profileghxNXdNo6qMeta?.path ?? "/de/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___fr",
    path: profileghxNXdNo6qMeta?.path ?? "/fr/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___it",
    path: profileghxNXdNo6qMeta?.path ?? "/it/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___he",
    path: profileghxNXdNo6qMeta?.path ?? "/he/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___hu",
    path: profileghxNXdNo6qMeta?.path ?? "/hu/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___nl",
    path: profileghxNXdNo6qMeta?.path ?? "/nl/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileghxNXdNo6qMeta?.name ?? "user-profile___uk-UA",
    path: profileghxNXdNo6qMeta?.path ?? "/uk-UA/user/profile",
    meta: profileghxNXdNo6qMeta || {},
    alias: profileghxNXdNo6qMeta?.alias || [],
    redirect: profileghxNXdNo6qMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___en___default",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___en",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/en/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___es",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/es/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___de",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/de/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___fr",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/fr/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___it",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/it/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___he",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/he/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___hu",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/hu/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___nl",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/nl/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93VmezcXtqPWMeta?.name ?? "user-videos-type___uk-UA",
    path: _91type_93VmezcXtqPWMeta?.path ?? "/uk-UA/user/videos/:type()",
    meta: _91type_93VmezcXtqPWMeta || {},
    alias: _91type_93VmezcXtqPWMeta?.alias || [],
    redirect: _91type_93VmezcXtqPWMeta?.redirect,
    component: () => import("/home/runner/work/ronaldo-web/ronaldo-web/pages/user/videos/[type].vue").then(m => m.default || m)
  }
]